<script lang="ts" setup>
import { computed, inject, toValue, type VNode } from 'vue';
import { AriaRole } from '../../shared/types';
import ObActionListItemContent from './ob-action-list-item-content.vue';
import { ACTION_LIST_CONTEXT } from './action-list-context';
import style from './ob-action-list-item.module.scss';

type Props = {
  role?: AriaRole;
  download?: string;
  href?: string;
  rel?: string;
  target?: string;
};

const { role, download, href, rel, target } = defineProps<Props>();

const emit = defineEmits<{
  select: [event: MouseEvent];
  click: [event: MouseEvent];
}>();

defineSlots<{
  default?: () => VNode;
  leadingVisual?: () => VNode;
  trailingVisual?: () => VNode;
  description?: () => VNode;
}>();

const { itemRole, compact, withDividers, onAfterSelect } = inject(ACTION_LIST_CONTEXT, {});

const roleAttribute = computed(() => role ?? toValue(itemRole));

function onClick(event: MouseEvent) {
  emit('select', event);

  if (event.defaultPrevented) {
    return;
  }

  if (typeof onAfterSelect === 'function') {
    onAfterSelect();
  }

  emit('click', event);
}
</script>

<template>
  <li :class="[style.wrapper, { [style.withDivider]: withDividers }]" role="none">
    <a
      :role="roleAttribute"
      :class="[
        style.control,
        {
          [style.compact]: toValue(compact),
        },
      ]"
      :download
      :href
      :rel
      :target
      @click="onClick"
    >
      <ObActionListItemContent>
        <template #leadingVisual>
          <slot name="leadingVisual" />
        </template>
        <slot />
        <template #description>
          <slot name="description" />
        </template>
        <template #trailingVisual>
          <slot name="trailingVisual" />
        </template>
      </ObActionListItemContent>
    </a>
  </li>
</template>
